.item:hover .ship {
  opacity: 1;
}

.n-warp {
  width: 70%;
  margin: 0 auto;
}

.bg-gradient {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 1) 100%);
}

@media (max-width: 767px) {
  .n-warp {
    width: 90%;
  }
}
