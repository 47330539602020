.ecosystem-item .ecosystem-item-o {
  transition: all ease-in 0.3s;
}

.ecosystem-item:hover .ecosystem-item-o {
  opacity: 1;
}

.ecosystem-item:hover .ecosystem-item-text {
  opacity: 1;
  color: #fff;
}

.ecosystem .ant-list-item {
  padding: 0 !important;
}
