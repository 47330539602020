@font-face {
  font-family: 'PressStart2P';
  src: url('../fonts/Press_Start_2P/PressStart2P-Regular.ttf');
}

html,
body {
  font-family: 'PressStart2P';
  color: #fff;
}

.type::after {
  content: '_' !important;
  color: #3ee5f0;
}

.ant-drawer-content {
  background: #161616 !important;
  backdrop-filter: blur(4px);
}

.ant-drawer-body {
  padding: 24px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
